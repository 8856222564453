import { ComponentPropsWithoutRef, FC } from 'react';
import { GalleryView } from '@/types';
import { LayoutList } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export const ToolbarLayoutListButton: FC<ComponentPropsWithoutRef<'button'> & { galleryView: GalleryView }> = (
  props,
) => (
  <Button
    variant="toolbar"
    size="toolbar"
    data-cy="toolbar-layout-list-button"
    title="List Layout"
    onClick={props.onClick}
  >
    <LayoutList
      className={cn(
        'size-6 min-h-6 min-w-6 duration-150',
        props.galleryView === GalleryView.LIST
          ? 'stroke-highlight-color hover:stroke-highlight-hover-color'
          : 'stroke-gray-icon-color hover:stroke-gray-icon-hover-color',
      )}
      strokeWidth={2}
    />
  </Button>
);
