import { FC, ComponentPropsWithoutRef } from 'react';
import { ZoomIn } from 'lucide-react';
import { Button } from '@/components/ui/button';

export const ToolbarZoomInButton: FC<ComponentPropsWithoutRef<'button'>> = (props) => (
  <Button variant="toolbar" size="toolbar" data-cy="toolbar-zoom-in-button" title="Zoom In" {...props}>
    <ZoomIn
      className="size-6 min-h-6 min-w-6 stroke-gray-icon-color duration-150 hover:stroke-gray-icon-hover-color"
      strokeWidth={2}
    />
  </Button>
);
