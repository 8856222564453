import { ComponentPropsWithoutRef, FC } from 'react';
import { GalleryView } from '@/types';
import { LayoutGrid } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

export const ToolbarLayoutTileButton: FC<ComponentPropsWithoutRef<'button'> & { galleryView: GalleryView }> = (
  props,
) => (
  <Button
    variant="toolbar"
    size="toolbar"
    data-cy="toolbar-layout-tile-button"
    title="Tile Layout"
    onClick={props.onClick}
  >
    <LayoutGrid
      className={cn(
        'size-6 min-h-6 min-w-6 duration-150',
        props.galleryView === GalleryView.TILE
          ? 'stroke-highlight-color hover:stroke-highlight-hover-color'
          : 'stroke-gray-icon-color hover:stroke-gray-icon-hover-color',
      )}
      strokeWidth={2}
    />
  </Button>
);
