import { FC, ComponentPropsWithoutRef } from 'react';
import { ZoomOut } from 'lucide-react';
import { Button } from '@/components/ui/button';

export const ToolbarZoomOutButton: FC<ComponentPropsWithoutRef<'button'>> = (props) => (
  <Button variant="toolbar" size="toolbar" data-cy="toolbar-zoom-out-button" title="Zoom Out" {...props}>
    <ZoomOut
      className="size-6 min-h-6 min-w-6 stroke-gray-icon-color duration-150 hover:stroke-gray-icon-hover-color"
      strokeWidth={2}
    />
  </Button>
);
