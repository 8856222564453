import { CSSProperties, useEffect } from 'react';
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from '@/components/ui/context-menu';
import { SortableContext, verticalListSortingStrategy, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Row, flexRender, useReactTable, VisibilityState } from '@tanstack/react-table';
import { Pen, Tag, Trash2Icon } from 'lucide-react';
import { SelectionEventProps } from '@/components/gallery-layout';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { UniqueIdentifier } from '@dnd-kit/core';
import { DuplicatedAsset } from '@/types/duplicates';
import { gridColsLookup } from '@/context/UserSettingsContext';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { DraggableItems } from '@/types/dragDrop';
import { GalleryView } from '@/types';
import { useDialog } from '@/context/DialogContext';
import { NodeType } from '@/types/tree';
import { useTree } from '@/hooks/data/tree/useTree';
import { Asset } from '@/types/asset';
import { CSS } from '@dnd-kit/utilities';
import { cn } from '@/lib/utils';

interface DataTableProps<TData extends Asset | DuplicatedAsset, TValue> {
  parentNodeId?: string;
  data: Array<TData>;
  galleryView?: GalleryView;
  assetSize: Array<number>;
  columnVisibility?: VisibilityState;
  table: ReturnType<typeof useReactTable<TData>>;
  selectedAssetIds: Array<{ id: string; name: string }>;
  dataIds: Array<UniqueIdentifier>;
  handleClick?: (event: SelectionEventProps, row: Row<TData>) => void;
  handleRightClick?: (asset: TData) => void;
  handleDoubleClick: (id: string) => Promise<boolean>;
}

export const DraggableItem = <TData extends Asset>({
  row,
  assets,
  parentNodeId,
  handleClick,
  handleDoubleClick,
  handleRightClick,
  isRow,
}: {
  row: Row<TData>;
  assets: Array<TData>;
  parentNodeId?: string;
  columnVisibility?: VisibilityState;
  handleClick?: (event: SelectionEventProps, row: Row<TData>) => void;
  handleDoubleClick: (id: string) => void;
  handleRightClick?: (asset: TData) => void;
  isRow?: boolean;
}) => {
  const { toggleNodeSelection, setCurrentSelectedType } = useTree();
  const { toggleAssetSelection, selectedAssetIds } = useAssetSelection();
  const { openModal } = useDialog();
  const { isPublicRoute, isTrashPage, isSearchPage } = useCurrentPage();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: row.original.id,
    data: {
      id: row.original.id,
      type: DraggableItems.ASSET,
      asset: row.original,
      parentNodeId: parentNodeId,
      accepts: DraggableItems.ASSET,
    },
    disabled: Boolean(isPublicRoute || isTrashPage || isSearchPage),
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: 'relative',
  };

  const isSelected = row.getIsSelected();

  useEffect(() => {
    if (isDragging && !isSelected) {
      toggleAssetSelection({
        id: row.original.id,
        name: row.original.name,
        assets,
        index: row.index,
      });

      toggleNodeSelection({
        id: row.original.nodeId,
        name: row.original.name,
        assets,
        index: row.index,
      });

      setCurrentSelectedType(NodeType.Assets);
    }
  }, [isDragging, isSelected, row, assets, toggleAssetSelection, toggleNodeSelection, setCurrentSelectedType]);

  return isRow ? (
    <TableRow
      ref={setNodeRef}
      {...(isSelected && { 'data-state': 'selected' })}
      onDoubleClick={() => handleDoubleClick(row.original.id)}
      style={style}
      {...attributes}
      {...listeners}
    >
      {row
        .getVisibleCells()
        .map((cell: { id: string; column: { columnDef: any; getSize: () => number }; getContext: () => any }) => {
          return (
            <TableCell
              key={cell.id}
              style={{ width: `${cell.column.getSize()}px` }}
              className={cn('select-none', cell.column.columnDef.meta?.cellClassName)}
              onClick={(event) => handleClick?.(event, row)}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
    </TableRow>
  ) : // Excluding right click context menu for public route
  isPublicRoute ? (
    <div
      {...(isSelected && { 'data-state': 'selected' })}
      onDoubleClick={() => handleDoubleClick(row.original.id)}
      onClick={(event) => handleClick?.(event, row)}
      className={cn('relative p-3 transition-colors duration-150', isSelected && 'h-full bg-light-ui-color')}
    >
      {row
        .getVisibleCells()
        .map((cell: { id: string; column: { columnDef: any; getSize: () => number }; getContext: () => any }) =>
          flexRender(cell.column.columnDef.cell, cell.getContext()),
        )}
    </div>
  ) : (
    <ContextMenu key={row.original.id}>
      <ContextMenuTrigger>
        <div
          {...(isSelected && { 'data-state': 'selected' })}
          ref={setNodeRef}
          onContextMenu={() => handleRightClick && handleRightClick(assets[row.index])}
          onDoubleClick={() => handleDoubleClick(row.original.id)}
          onClick={(event) => handleClick?.(event, row)}
          style={style}
          {...attributes}
          {...listeners}
          className={cn('h-full p-3 transition-colors duration-150', isSelected && 'bg-light-ui-color')}
        >
          {row
            .getVisibleCells()
            .map((cell: { id: string; column: { columnDef: any; getSize: () => number }; getContext: () => any }) =>
              flexRender(cell.column.columnDef.cell, cell.getContext()),
            )}
        </div>
      </ContextMenuTrigger>
      <ContextMenuContent className="w-40">
        <ContextMenuItem disabled>
          <Pen className="mr-2 size-4" />
          Edit asset
        </ContextMenuItem>
        <ContextMenuItem disabled>
          <Tag className="mr-2 size-4" />
          Edit Keywords
        </ContextMenuItem>
        <ContextMenuSeparator />
        <ContextMenuItem
          onClick={() => openModal('deleteConfirmation')}
          className="text-red-600 focus:bg-red-100 focus:text-red-600"
        >
          <Trash2Icon className="mr-2 size-4" />
          Delete Asset{selectedAssetIds.length > 1 ? 's' : ''}
        </ContextMenuItem>
      </ContextMenuContent>
    </ContextMenu>
  );
};

export function DataTableGridListView<TData extends Asset | DuplicatedAsset, TValue>({
  parentNodeId,
  galleryView,
  assetSize,
  columnVisibility,
  table,
  data,
  dataIds,
  handleClick,
  handleDoubleClick,
  handleRightClick,
}: DataTableProps<TData, TValue>) {
  const outerContainerClass = table.getRowModel().rows.some((row) => row.getIsGrouped())
    ? // TODO: Remove max-h-[calc(100vh-101px)] once the layout is fixed
      'flex max-h-[calc(100vh-101px)] flex-col overflow-y-auto'
    : `relative grid max-h-[calc(100vh-133px)] gap-3 overflow-y-auto p-6 ${
        gridColsLookup[assetSize[0]] || 'grid-cols-7'
      }`;

  return galleryView === GalleryView.LIST ? (
    // TODO: Remove max-h-[calc(100vh-101px)] once the layout is fixed
    <div className="flex max-h-[calc(100vh-101px)] overflow-hidden">
      <Table>
        <TableHeader className="rounded-none">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead
                  key={header.id}
                  className={cn((header.column.columnDef.meta as { headerClassName: string })?.headerClassName)}
                  style={{ width: `${header.getSize()}px` }}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
            {table.getRowModel().rows.map((row) =>
              row.getIsGrouped() ? (
                <>
                  <tr key={row.id} className="h-10">
                    <td colSpan={table.getAllColumns().length}>
                      <hr className="h-px border-0 bg-ui-bevel" />
                    </td>
                  </tr>
                  {row.getLeafRows().map((leafRow) => (
                    <DraggableItem
                      isRow
                      key={leafRow.id}
                      row={leafRow}
                      assets={data}
                      parentNodeId={parentNodeId}
                      handleClick={handleClick}
                      handleDoubleClick={handleDoubleClick}
                    />
                  ))}
                </>
              ) : (
                <DraggableItem
                  isRow
                  key={row.id}
                  row={row}
                  assets={data}
                  parentNodeId={parentNodeId}
                  handleClick={handleClick}
                  handleDoubleClick={handleDoubleClick}
                />
              ),
            )}
          </SortableContext>
        </TableBody>
      </Table>
    </div>
  ) : (
    <div className={outerContainerClass}>
      <SortableContext items={dataIds} strategy={rectSortingStrategy}>
        {table.getRowModel().rows.map((row) =>
          row.getIsGrouped() ? (
            <div
              key={row.id}
              className={cn(
                'relative grid gap-3 border-b border-ui-bevel p-6 last:border-b-0',
                gridColsLookup[assetSize[0]] || 'grid-cols-7',
              )}
            >
              {row.getLeafRows().map((leafRow) => (
                <DraggableItem
                  key={leafRow.id}
                  row={leafRow}
                  assets={data}
                  parentNodeId={parentNodeId}
                  columnVisibility={columnVisibility}
                  handleClick={handleClick}
                  handleDoubleClick={handleDoubleClick}
                  handleRightClick={handleRightClick}
                />
              ))}
            </div>
          ) : (
            <DraggableItem
              key={row.id}
              row={row}
              assets={data}
              parentNodeId={parentNodeId}
              columnVisibility={columnVisibility}
              handleClick={handleClick}
              handleDoubleClick={handleDoubleClick}
              handleRightClick={handleRightClick}
            />
          ),
        )}
      </SortableContext>
    </div>
  );
}
