import { ReactNode, MouseEvent, useEffect, useState, useMemo, ChangeEvent } from 'react';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScanSearch, Trash2Icon, GripHorizontal, Share, Eye, Upload, Trash2 } from 'lucide-react';
import { sortOptions, SortOrderOptions, SortOrderValues, SortState } from '@/types/sort';
import { ColumnDef, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { type UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';
import { ToolbarLayoutListButton } from '@/components/toolbars/toolbar-layout-list-button';
import { ToolbarLayoutTileButton } from '@/components/toolbars/toolbar-layout-tile-button';
import { ToolbarSortOrderButton } from '@/components/toolbars/toolbar-sort-order-button';
import { IndeterminateCheckbox } from '@/components/data-table/data-table-checkbox';
import { DataTableGridListView } from '@/components/data-table/data-table-grid-list-view';
import { ReorderAssetsCommand } from '@/hooks/commands/albums/ReorderAssetsCommand';
import { ToolbarZoomOutButton } from '@/components/toolbars/toolbar-zoom-out-button';
import { ToolbarZoomInButton } from '@/components/toolbars/toolbar-zoom-in-button';
import { ToolbarFilterButton } from '@/components/toolbars/toolbar-filter-button';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCommandContext } from '@/context/CommandContext';
import { Row, SortingState } from '@tanstack/react-table';
import { CustomPagination } from '@/components/pagination';
import { Asset, GetAssets } from '@/types/asset';
import { useUserSettings } from '@/context/UserSettingsContext';
// import { CustomDragLayer } from '@/components/custom-drag-layer';
import { AssetThumbnail } from '@/components/asset-thumbnail';
import { useQueryClient } from '@tanstack/react-query';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { DraggableItems } from '@/types/dragDrop';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { usePagination } from '@/hooks/usePagination';
import { CaretDownIcon } from '@radix-ui/react-icons';
import { FilterSelect } from '@/components/ui/filter-select';
import { BackendError } from '@/hooks/useFormErrorHandler';
import { GalleryView } from '@/types';
import { RenderGrid } from '@/components/render-grid';
import { useDialog } from '@/context/DialogContext';
import { arrayMove } from '@dnd-kit/sortable';
import { FilterBar } from '@/components/toolbars/filter-bar';
import { useRouter } from 'next/router';
import { Skeleton } from '@/components/ui/skeleton';
import { Variants } from '@/types/asset';
import { NodeType } from '@/types/tree';
import { useTree } from '@/hooks/data/tree/useTree';
import { Toolbar } from '@/components/toolbars/toolbar';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

export type SelectionEventProps = MouseEvent<HTMLTableCellElement | HTMLDivElement> | undefined;

export type GalleryLayoutProps = {
  pageTitle: string;
  pageDescription?: string;
  data?: { assets?: GetAssets; nodeId?: string };
  resizeValue: number;
  isLoading?: boolean;
  emptyState?: ReactNode;
  sort?: SortState;
  handleOnSortChange?: (value: string) => void;
  handleOnSortOrderChange?: () => void;
  queryString?: string;
  handleOnSearch?: (value: ChangeEvent<HTMLInputElement>) => void;
  showFilterInput?: boolean;
  setShowFilterInput?: (showFilterInput: boolean) => void;
  view?: GalleryView;
  onError?: BackendError | Error | null;
};

const RowDragHandleCell = ({ row }: { row: Row<Asset> }) => (
  <button className="mx-auto flex h-10 w-full cursor-move items-center justify-center !p-0">
    <GripHorizontal
      className={cn('size-5', row.getIsSelected() ? 'stroke-dark-gray-text' : 'stroke-separator-color', {})}
    />
  </button>
);

export const GalleryLayout = ({
  data,
  resizeValue,
  isLoading = false,
  emptyState,
  sort,
  handleOnSortChange,
  handleOnSortOrderChange,
  queryString,
  handleOnSearch,
  showFilterInput,
  setShowFilterInput,
  view,
  onError,
}: GalleryLayoutProps) => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const [items, setItems] = useState<Array<Asset>>([]);
  const [columnOrder, setColumnOrder] = useState<Array<string>>([
    'drag-handle',
    'select',
    'thumbnail',
    'name',
    'type',
    'nodeId',
    'slug',
    'description',
  ]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const { query } = router;
  const { results, page } = query;
  const { selectedAssetIds, deselectAllAssets, contextAssetSelection, toggleAssetSelection } = useAssetSelection();
  const {
    setCurrentSelectedType,
    currentSelectedType,
    contextNodeSelection,
    selectedAlbum: album,
    toggleNodeSelection,
  } = useTree();
  const {
    assetSize,
    galleryView: galleryViewUserSettings,
    setGalleryView,
    setAssetSize,
    visibleColumns,
    setVisibleColumns,
  } = useUserSettings();
  const { apply } = useCommandContext();
  const { isMobile } = useBreakpoint();
  const { openModal } = useDialog();
  const { isSearchPage } = useCurrentPage();
  const { nodeId, assets } = data ?? {};

  const galleryView = view ? view : galleryViewUserSettings; // Overwrite galleryView with `view` prop if it exists (search page)
  const currentPage = (assets?.pagination?.offset ?? 0) / (assets?.pagination?.limit ?? 0) + 1;
  const totalCount = assets?.pagination?.count ?? 0;
  const pageSize = assets?.pagination?.limit ?? 0;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: 2,
    pageSize,
  });
  const lastPage = paginationRange?.[paginationRange.length - 1];
  const images = assets?.assets;

  useEffect(() => {
    if (images) {
      setItems(images);
    }
  }, [images]);

  const reorderAssetsCommand = ReorderAssetsCommand(
    selectedAssetIds.map((asset) => asset.id),
    album,
  );

  const handleRightClick = (asset: Asset) => {
    contextNodeSelection({ id: asset.nodeId, name: asset.name });
    setCurrentSelectedType(NodeType.Assets);
    contextAssetSelection({ id: asset.id, name: asset.name });
  };

  const dataIds = useMemo<Array<UniqueIdentifier>>(() => items?.map(({ id }) => id) || [], [items]);

  const tableColumns: Array<ColumnDef<Asset>> = useMemo(
    () => [
      {
        id: 'drag-handle',
        header: '',
        meta: {
          cellClassName: '!p-0',
        },
        cell: ({ row }) => galleryView === GalleryView.LIST && <RowDragHandleCell row={row} />,
        size: 30,
        minSize: 30,
        maxSize: 30,
      },
      {
        id: 'select',
        header: ({ table }) => {
          return (
            <IndeterminateCheckbox
              className="mx-auto hover:cursor-default"
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
          );
        },
        meta: {
          headerClassName: '!p-0',
          cellClassName: '!p-0',
        },
        cell: ({ row }) =>
          galleryView === GalleryView.LIST && (
            <IndeterminateCheckbox
              className="mx-auto"
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        size: 30,
      },
      {
        accessorKey: 'thumbnail',
        header: '',
        meta: {
          cellClassName: '!p-0 relative',
        },
        size: 20,
        minSize: 20,
        maxSize: 20,
        cell: ({ row }) => {
          const iconSizes =
            galleryView === GalleryView.TILE
              ? assetSize[0] === 0
                ? 'size-10'
                : assetSize[0] === 20
                ? 'size-12'
                : assetSize[0] === 40
                ? 'size-14'
                : assetSize[0] === 60
                ? 'size-16'
                : 'size-20'
              : 'size-6';

          return (
            <>
              {row.original.trashed && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <Trash2 className={cn('stroke-red-500 dark:stroke-red-800', iconSizes)} strokeWidth={1.5} />
                </div>
              )}
              <AssetThumbnail
                name={row.original.name}
                fileType={row.original.type}
                thumbnail={row.original.variants[Variants.ThumbnailInternal]?.url}
                iconSize={200}
                draggable={false}
                width={150}
                height={150}
                fill={false}
                imageClassName={cn(
                  'w-full h-full min-h-full min-w-full transition-all',
                  galleryView === GalleryView.TILE && 'mb-1 aspect-square object-contain',
                  galleryView === GalleryView.LIST && 'object-cover',
                  {
                    'opacity-50': row.original.trashed,
                  },
                )}
                iconClassName={cn(
                  'stroke-light-ui-color',
                  iconSizes,
                  galleryView === GalleryView.LIST && row.getIsSelected() && '!stroke-dark-gray-text',
                )}
                imageContainerClassName={cn(
                  'bg-panel-background',
                  galleryView === GalleryView.LIST ? 'absolute inset-0' : 'relative',
                )}
                iconContainerClassName={cn(
                  'flex aspect-square h-auto w-full items-center justify-center bg-panel-background',
                  galleryView === GalleryView.LIST && row.getIsSelected() && 'bg-light-ui-color ',
                )}
                overlayIconClassName={cn(
                  galleryView === GalleryView.LIST && 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
                )}
              />
            </>
          );
        },
      },
      {
        accessorKey: 'name',
        header: () => <div className="text-center">Name</div>,
        meta: {
          cellClassName: 'text-left dark:text-white text-black font-semibold',
        },
        cell: ({ row }) => {
          return galleryView === GalleryView.LIST ? (
            <p title={row.original.name} className="line-clamp-1 break-all">
              {row.original.name}
            </p>
          ) : (
            <p className="line-clamp-1 text-sm font-medium text-neutral-950 dark:text-neutral-50 lg:line-clamp-2">
              {row.original.name}
            </p>
          );
        },
      },
      {
        accessorKey: 'type',
        header: () => <div className="text-center">Type</div>,
        meta: {
          cellClassName: 'text-center',
        },
        size: 70,
        cell: ({ row }) => (
          <Badge className={cn('capitalize', galleryView === GalleryView.TILE && 'absolute right-0 top-2 ')}>
            {row.original.type}
          </Badge>
        ),
      },
      {
        accessorKey: 'nodeId',
        header: 'NodeId',
        cell: ({ row }) => (
          <p title={row.original.nodeId} className="line-clamp-1 break-all text-xs text-gray-text">
            {row.original.nodeId}
          </p>
        ),
      },
      {
        accessorKey: 'slug',
        header: 'Slug',
        cell: ({ row }) => (
          <p title={row.original.slug} className="line-clamp-1 break-all text-xs text-gray-text">
            {row.original.slug}
          </p>
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
        cell: ({ row }) => (
          <p title={row.original.description} className="line-clamp-1 break-all text-xs text-gray-text">
            {row.original.description}
          </p>
        ),
      },
    ],
    [galleryView, assetSize],
  );

  // Sync table rowSelection with the selectedAssetIds
  const rowSelection = useMemo(() => {
    const selection: Record<string, boolean> = {};

    selectedAssetIds.forEach(({ id }) => {
      selection[id] = true;
    });

    return selection;
  }, [selectedAssetIds]);

  const isDevelopment = process.env.NODE_ENV === 'development' ? true : false;

  const table = useReactTable({
    data: items,
    columns: tableColumns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      columnVisibility: visibleColumns,
      columnOrder,
      sorting,
      rowSelection: rowSelection,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onColumnVisibilityChange: setVisibleColumns,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    debugTable: isDevelopment,
    debugHeaders: isDevelopment,
    debugColumns: isDevelopment,
  });

  useDndMonitor({
    onDragEnd(event) {
      const { active, over } = event;

      // Reorder assets when manual sorting is enabled
      if (
        sort?.value === SortOrderValues.MANUAL &&
        over?.data.current?.accepts.includes(active.data.current?.type) &&
        active?.data?.current?.type === DraggableItems.ASSET &&
        active.id !== over.id
      ) {
        setItems((dragData) => {
          const oldIndex = dataIds.indexOf(active.id);
          const newIndex = dataIds.indexOf(over.id);

          const position = Number(results ?? 0) * (Number(page ?? 1) - 1) + (newIndex + 1); //adding 1 because api accept position from 1 instead of 0
          reorderAssetsCommand.update({ position, oldPosition: oldIndex + 1 });
          apply(reorderAssetsCommand);

          return arrayMove(dragData, oldIndex, newIndex);
        });
      }
    },
  });

  const handleSelection = <TData extends Asset>(event: SelectionEventProps, row: Row<TData>) => {
    if (!event) {
      return;
    }

    const keys = {
      metaKey: event.metaKey,
      shiftKey: event.shiftKey,
      ctrlKey: event.ctrlKey,
    };

    toggleAssetSelection({
      id: row.original.id,
      name: row.original.name,
      assets: items,
      index: row.index,
      ...keys,
    });

    toggleNodeSelection({
      id: row.original.nodeId,
      name: row.original.name,
      assets: items,
      index: row.index,
      ...keys,
    });
    setCurrentSelectedType(NodeType.Assets);
  };

  const handleDoubleClick = (id: string) =>
    router.push({
      pathname: `/asset/[id]`,
      query: {
        id,
        // Conditionally include 'folder' and 'album' in the query parameters
        // Only add 'folder' and 'album' if they exist in the current router query
        ...(query.folder && { folder: query.folder }),
        ...(query.album && { album: query.album }),
      },
    });

  return (
    <>
      {!isSearchPage && (
        <>
          <Toolbar className="mt-[53px]">
            <div className="flex flex-1 gap-x-2 border-r border-t border-toolbar-bevel">
              <div className="flex grow items-center gap-x-2">
                {currentSelectedType === NodeType.Folders && (
                  <div
                    className="mr-4 flex cursor-pointer"
                    onClick={() => {
                      openModal('uploadAsset');
                    }}
                  >
                    <Upload className="mr-2 size-4 text-neutral-400" />
                    <div className="cursor-pointer text-xs">Upload assets</div>
                  </div>
                )}
                {currentSelectedType === NodeType.Albums && nodeId && (
                  <Button
                    variant="ghost"
                    className="flex gap-x-2 px-2"
                    size="sm"
                    onClick={() => {
                      openModal('createShare', 'createShare', {
                        id: nodeId,
                      });
                    }}
                    data-cy="toolbar-share-album-button"
                    title="Share Album"
                  >
                    <Share className="size-[18px] stroke-gray-icon-color" />
                    <div className="text-xs text-black dark:text-white">Share</div>
                  </Button>
                )}
                {selectedAssetIds.length > 0 && (
                  <Button
                    variant="outline"
                    className="flex gap-x-2 px-2"
                    size="sm"
                    onClick={() => openModal('deleteConfirmation')}
                    data-cy="toolbar-delete-remove-asset-button"
                    title={`${album ? 'Remove' : 'Delete'} asset${selectedAssetIds.length > 1 ? 's' : ''}`}
                  >
                    <Trash2Icon className="size-[18px] stroke-gray-icon-color" />
                    <div className="whitespace-nowrap text-xs text-black dark:text-white">{`${
                      album ? 'Remove' : 'Delete'
                    } asset${selectedAssetIds.length > 1 ? 's' : ''}`}</div>
                  </Button>
                )}
              </div>
              <ToolbarFilterButton
                showFilterInput={showFilterInput}
                onClick={() => setShowFilterInput && setShowFilterInput(!showFilterInput)}
              />
            </div>
            <div className="flex divide-x divide-void-gap">
              {sort?.value && handleOnSortChange && (
                <FilterSelect
                  value={sort?.value}
                  onValueChange={handleOnSortChange}
                  // Exclude "manual sorting" when folder is selected
                  options={sortOptions.filter((option) =>
                    option.value === SortOrderValues.MANUAL && album === undefined ? false : true,
                  )}
                />
              )}
              <div className="flex">
                <div className="flex items-center gap-x-2 border-l border-t border-toolbar-bevel px-3">
                  <DropdownMenu>
                    <DropdownMenuTrigger className="group/display flex items-center">
                      <Eye
                        className={cn(
                          'size-6 min-h-6 min-w-6 stroke-gray-icon-color duration-150 group-hover/display:stroke-gray-icon-hover-color group-data-[disabled]/display:!stroke-gray-icon-color group-data-[state=open]/display:!stroke-highlight-color group-data-[disabled]/display:opacity-50',
                        )}
                        strokeWidth={2.5}
                      />
                      <CaretDownIcon
                        className={cn(
                          'size-8 min-h-8 min-w-8 text-gray-icon-color transition-transform duration-150 group-data-[state=open]/display:rotate-180 group-data-[disabled]/display:opacity-50',
                        )}
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {table
                        .getAllColumns()
                        .filter(
                          (column) =>
                            column.getCanHide() &&
                            !(column.id === 'thumbnail') &&
                            !(column.id === 'drag-handle') &&
                            !((galleryView === GalleryView.TILE && column.id) === 'select'),
                        )
                        .map((column) => (
                          <DropdownMenuCheckboxItem
                            key={column.id}
                            className="capitalize"
                            checked={column.getIsVisible()}
                            onCheckedChange={(value) => column.toggleVisibility(Boolean(value))}
                          >
                            {column.id}
                          </DropdownMenuCheckboxItem>
                        ))}
                    </DropdownMenuContent>
                  </DropdownMenu>

                  <ToolbarSortOrderButton
                    sort={{ order: sort?.order as SortOrderOptions }}
                    onClick={handleOnSortOrderChange}
                  />
                  <ToolbarLayoutListButton galleryView={galleryView} onClick={() => setGalleryView(GalleryView.LIST)} />
                  <ToolbarLayoutTileButton galleryView={galleryView} onClick={() => setGalleryView(GalleryView.TILE)} />
                  <ToolbarZoomOutButton
                    disabled={galleryView === GalleryView.LIST || assetSize[0] === 0}
                    onClick={() =>
                      setAssetSize((prevSize) => {
                        const newValue = Math.max(prevSize[0] - 20, 0);
                        setAssetSize([newValue]);

                        return [newValue];
                      })
                    }
                  />
                  <ToolbarZoomInButton
                    disabled={galleryView === GalleryView.LIST || assetSize[0] === 100}
                    onClick={() =>
                      setAssetSize((prevSize) => {
                        const newValue = Math.min(prevSize[0] + 20, 100);
                        setAssetSize([newValue]);

                        return [newValue];
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </Toolbar>
          {showFilterInput && handleOnSearch && (
            <FilterBar showFilterInput={showFilterInput} queryString={queryString} onChange={handleOnSearch} />
          )}
        </>
      )}
      {isLoading ? (
        <RenderGrid
          isSearchPage={isSearchPage}
          isMobile={isMobile}
          galleryView={view ? view : galleryView}
          assetSize={assetSize}
        >
          {Array.from(Array(16)).map((_, index) => (
            <div
              key={`gallery-skeleton-${index}`}
              className={cn(
                isMobile
                  ? galleryView === GalleryView.LIST
                    ? 'grid grid-cols-[100px_1fr] gap-5 space-y-3'
                    : 'grid grid-rows-[1fr_auto] gap-3'
                  : galleryView === GalleryView.LIST
                  ? 'grid grid-cols-[100px_1fr] gap-5 space-y-3'
                  : 'grid gap-2',
              )}
            >
              <Skeleton className="flex aspect-square h-auto w-full bg-neutral-100 dark:bg-neutral-900" />
              <div className="!mt-0 flex flex-col justify-center gap-2 text-sm">
                <Skeleton className="h-4 w-11/12 bg-neutral-100 dark:bg-neutral-900" />
                <Skeleton className="h-6 w-9/12 bg-neutral-100 dark:bg-neutral-900" />
              </div>
            </div>
          ))}
        </RenderGrid>
      ) : (
        <>
          {onError ? (
            <div className="flex h-full flex-col items-center justify-center">
              <h1 className="mb-4 text-3xl font-bold text-neutral-950 dark:text-neutral-50">
                Oops! Something went wrong.
              </h1>
              <p className="mb-8 text-lg text-neutral-600 dark:text-neutral-400">Please try again later.</p>
              {!isSearchPage && (
                <Button onClick={() => queryClient.invalidateQueries({ queryKey: ['assetData'] })}>Retry</Button>
              )}
            </div>
          ) : items?.length ? (
            <>
              <DataTableGridListView
                parentNodeId={nodeId}
                galleryView={view ? view : galleryView} // /search page overrides gallery view
                assetSize={assetSize}
                columnVisibility={visibleColumns}
                table={table}
                data={items}
                selectedAssetIds={selectedAssetIds}
                dataIds={dataIds}
                handleClick={handleSelection}
                handleRightClick={handleRightClick}
                handleDoubleClick={handleDoubleClick}
              />
              {assets?.pagination && assets?.pagination.count > assets?.pagination.limit && (
                <div className="absolute inset-x-0 bottom-0">
                  <CustomPagination
                    totalCount={totalCount}
                    siblingCount={resizeValue < 52.5 ? 1 : 2}
                    onlyIcons={resizeValue < 44}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    className="relative z-20 p-4"
                    deselectAssetsOnPageChange
                    handlePrev={{
                      href: {
                        query: {
                          ...query,
                          page: currentPage === 1 ? 1 : currentPage - 1,
                        },
                      },
                      onClick: deselectAllAssets,
                      disabled: Number(currentPage) === 1 || Number(currentPage) === 0,
                    }}
                    handleNext={{
                      href: {
                        query: {
                          ...query,
                          page: currentPage === lastPage ? lastPage : currentPage === 0 ? 2 : currentPage + 1,
                        },
                      },
                      onClick: deselectAllAssets,
                      disabled: currentPage === lastPage,
                    }}
                  />
                  <div className="pointer-events-none absolute inset-0 -top-14 z-10 [background-image:linear-gradient(to_top,white_30%,transparent_100%)] dark:[background-image:linear-gradient(to_top,black_30%,transparent_100%)]" />
                  <div className="pointer-events-none absolute inset-0 -top-5 z-10 backdrop-blur-[5px] [mask-image:linear-gradient(to_top,white_50%,transparent_100%)]" />
                </div>
              )}
            </>
          ) : emptyState ? (
            emptyState
          ) : (
            <div className="flex h-screen flex-col items-center justify-center gap-2">
              <ScanSearch className="mb-10 size-20 text-neutral-300 dark:text-neutral-600" strokeWidth={1} />
              <h4 className="text-balance text-center text-2xl">No Assets Found</h4>
              <div className="max-w-md text-balance px-16 text-center text-neutral-600">
                The selected {currentSelectedType === NodeType.Assets ? 'asset' : 'folder'} does not contain any assets.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
